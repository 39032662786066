import React from 'react'
import HeroSection from './Hero-Section/index';
import TaxiToSchiphol from './Testimonial/index';
import NetherlandAirports from './Netherland-Airports';
import GermanAirports from './German-Airports';
import ShowRating from './Show-Rating';
import FixedPrices from './Fixed-Prices';

const CityComponent = () => {
  return ( 
    <>
    <h1>City</h1>
    {/* <HeroSection wpData={wpData}/>
    <TaxiToSchiphol />
    <FixedPrices wpData={wpData}/>
    <NetherlandAirports />
    <GermanAirports />
    <ShowRating /> */}
    </>
   );
}
 
export default CityComponent;