import React from 'react'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { useContext } from 'react'

import { LanguageContext } from '../../../lang'
import './index.css'

const redLocation = require('../../../images/airport-transport/red-location1.png')

const NetherlandAirports = () => {
    return (
        <>
            <h1>Placeholder</h1>
            {/* <section id='netherland-airports'>
                <div className='container '>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Card className="netherland-airports-card-div">
                                <CardBody className='netherland-airports-card'>
                                    <CardTitle className='netherland-airports-card-title'>
                                        Our most popular airport in the Netherlands is of course Schiphol,
                                        but apart from that we also transport passengers almost daily to:
                                    </CardTitle>
                                    <ul className='netherland-airports-card-ul'>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Eindhoven Airport
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>The Hague Airport

                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Airport Den Haag
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Maastricht Aachen Airport
                                            </p>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-lg-6'>
                            <Card>
                                <CardBody >
                                    <CardTitle className='netherland-airports-card-title'>
                                        In addition to in the Netherlands, we also transport passengers
                                        to Belgium airport:
                                    </CardTitle>
                                    <ul className='netherland-airports-card-ul'>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Brussels Zaventem Airport
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Charleroi Brussels South Airport

                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Oostende-Brugge Airport
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Antwerpen Airport
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <img src={redLocation} alt='redLocation' />
                                                <span>&nbsp;&nbsp;</span>Luik Airport
                                            </p>
                                        </li>
                                    </ul>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
}

export default NetherlandAirports;