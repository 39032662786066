import React from 'react';
import { graphql } from 'gatsby'
import CityComponent from './../components/City/index';
import Layout from '../components/Layout/layout';

const City = () => {
  // console.log("WP IN PAGE****",data)
  return ( 
    <Layout>
      <CityComponent/>
    </Layout>
   );
}
 
// export const pageQuery = graphql`
//   query {
//     allWordpressPost{
//     edges{
//       node{
//         id
//         slug
//         status
//         acf{
//           test1
//           test2
//           herotext1
//           herotext2
//         }
//       }
//     }
//   }
//   }
// `


export default City;